export default [
  {
    title: 'Clients',
    icon: 'FolderIcon',
    children: [
      {
        title: 'Manage Clients',
        route: 'browse-client',
        // acl
        action: 'browse',
        resource: 'Client',
      },
      {
        title: 'Create Client',
        route: 'create-client',
        // acl
        action: 'create',
        resource: 'Client',
      },
    ],
  },
]
